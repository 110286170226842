import { useAlertContext } from '../../Context/AlertContext';
import { useStores } from '../../../../root-store-context';
import { SubscriptionType } from '../../../../services/SubscriptionService';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import classes from './CabinetSubscriptionPlans.module.css';

type CabinetSubscriptionPlansProps = {
    isDonator?: boolean | null;
    isPromoActive?: boolean;
}

function CabinetSubscriptionPlans({ isDonator, isPromoActive }: CabinetSubscriptionPlansProps) {
    const { subscription } = useStores();
    const showAlert = useAlertContext();

    const subscriptionPlan = isPromoActive ? 'MTH_PROMO_24' : 'MTH';
    const price = isPromoActive ? '500' : '1 000';
    const type = isPromoActive ? 'Месяц Промо' : 'Месяц';

    const handleSubscribe = async (type: SubscriptionType) => {
        const values = {
            type: type
        };

        try {
            await subscription.create({
                type: values.type
            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert(
                    'Произошла ошибка',
                    'error'
                );
            }
        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
            showAlert(
                'Произошла ошибка',
                'error'
            );
        }
    };

    const handleUpdateSubscription = async (type: SubscriptionType) => {
        const values = {
            type: type
        };

        try {
            await subscription.update({
                type: values.type
            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert(
                    'Произошла ошибка',
                    'error'
                );
            }
        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
            showAlert(
                'Произошла ошибка',
                'error'
            );
        }
    };

    return (
        <Box className={classes['cabinet-subscription-plans__container']}>
            <Typography variant='h3'>
                Тарифные планы
            </Typography>
            <Box className={classes['cabinet-subscription-plans__list']}>
                <Card elevation={4} className={classes['cabinet-subscription-plans__item']}>
                    <Typography variant="h3">{type}</Typography>
                    <Typography variant="subtitle2">{price} ₽</Typography>
                    <Typography sx={{ textAlign: 'center' }}>
                        Платёж будет автоматически списываться каждый месяц.
                    </Typography>
                    {isDonator && (
                        <Button onClick={() => handleUpdateSubscription(subscriptionPlan)} sx={{ marginBlockStart: 'auto' }} variant="contained">
                            Обновить подписку за 1 рубль
                        </Button>
                    )}
                    {!isDonator && (
                        <Button onClick={() => handleSubscribe(subscriptionPlan)} sx={{ marginBlockStart: 'auto' }} variant="contained">
                            Подписаться
                        </Button>
                    )}
                </Card>
            </Box>
        </Box>
    );
}

export default CabinetSubscriptionPlans;
