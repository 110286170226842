import { useEffect, useState } from 'react';
import { useStores } from '../../../../../root-store-context';
import useLocale from '../../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../Context/AlertContext';
import { format } from 'date-fns';
import { ru, enGB } from 'date-fns/locale';
import { Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import CabinetSubscriptionPlans from '../../CabinetSubscriptionPlans/CabinetSubscriptionPlans';
import CustomContainer from '../../../CustomContainer/CustomContainer';
import { NAVIGATION_ROUTES, SUBSCRIPTION_TYPE_MAP } from '../../../../../const';
import classes from '../../Cabinet.module.css'

function CabinetSettingsSubscriptionStatusPromo() {
    const { user, subscription } = useStores();
    const userData = user.data;
    const subscriptionData = subscription?.data;
    const [isLoading, setIsLoading] = useState(true);
    const [isAutoRenew, setIsAutoRenew] = useState(subscriptionData?.autoRenew);
    const intl = useIntl();
    const showAlert = useAlertContext();
    const { locale } = useLocale();

    useEffect(() => {
        if (!subscriptionData) {
            subscription
                .get()
                .then(() => setIsLoading(false))
                .catch((error) => {
                    setIsLoading(false);
                    showAlert(
                        `${intl.formatMessage({
                            defaultMessage: 'Произошла ошибка:',
                            id: 'Cabinet_ErrorMessage',
                            description: 'кабинет'
                        })} ${error}`,
                        error
                    );
                });
        } else {
            setIsLoading(false);
        }
    }, [subscriptionData, subscription]);

    useEffect(() => {
        if (subscriptionData) {
            setIsAutoRenew(subscriptionData.autoRenew);
        }
    }, [subscriptionData]);

    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    const mapSubscriptionType = (type: string | undefined): string => {
        return SUBSCRIPTION_TYPE_MAP[type || ''] || 'Неизвестно';
    };

    const subscriptionEndDate = subscriptionData?.activeUntil
        ? format(
            new Date(
                new Date(subscriptionData.activeUntil).setDate(
                    new Date(subscriptionData.activeUntil).getDate() - 1
                )
            ),
            'PPP',
            {
                locale: locale === 'ru' ? ru : enGB
            }
        )
        : undefined;

    if (isLoading) {
        return (
            <div>
                <Typography>
                    <FormattedMessage
                        defaultMessage="Загрузка данных о подписке"
                        id="Cabinet_SubscriptionDataLoad"
                        description="кабинет"
                    />
                </Typography>
                <CircularProgress />
            </div>
        );
    }

    if (!userData?.isActivated) {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <p className={classes['cabinet-settings__text']}>
                    Чтобы иметь возможность оформлять подписку, в том числе и пробную, подтвердите
                    ваш email.
                </p>
            </CustomContainer>
        );
    }

    if (subscriptionData?.type === 'LFT') {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>
                    <p className={classes['cabinet-settings__text']}>
                        Тарифный план: <b>{mapSubscriptionType(subscriptionData?.type)}</b>
                    </p>
                    <p className={classes['cabinet-settings__text']}>Спасибо вам за поддержку!</p>
                </div>
            </CustomContainer>
        );
    }

    if (!subscriptionData?.hasFirstSubscriptionId && subscriptionData?.isActive && subscriptionData?.type === 'TRIAL') {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>
                    <p className={classes['cabinet-settings__text']}>
                        Тарифный план: <b>{mapSubscriptionType(subscriptionData?.type)}</b>
                    </p>
                    <p className={classes['cabinet-settings__text']}>
                        Подписка активна до:
                        {' '}
                        <b>{subscriptionEndDate}</b>
                    </p>

                    <p className={classes['cabinet-settings__text']}>
                        Пока действует пробный доступ вы можете приобрести тарифный план с платежом в 1 рубль. Списания по тарифу начнутся, когда кончится период пробного доступа. Платёж в 1 рубль нужен, чтобы запомнить вашу банковскую карту.<br />
                        Подписку можно будет отменить в любой момент, и доступ к приложению останется до конца оплаченного периода.
                    </p>
                    <div
                        className={`${classes['cabinet-settings__forms']} ${classes['cabinet-settings__forms--no-offset']}`}
                    >
                        <CabinetSubscriptionPlans isPromoActive isDonator />
                    </div>
                </div>
            </CustomContainer>
        );
    }

    if (!subscriptionData?.hasFirstSubscriptionId && subscriptionData?.isActive && subscriptionData?.type !== 'TRIAL') {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>
                    <p className={classes['cabinet-settings__text']}>
                        Тарифный план: <b>{mapSubscriptionType(subscriptionData?.type)}</b>
                    </p>
                    <p className={classes['cabinet-settings__text']}>
                        Подписка активна до:
                        {' '}
                        <b>{subscriptionEndDate}</b>
                    </p>

                    <p className={classes['cabinet-settings__text']}>
                        Так как вы приобрели месяц доступа к WattAttack во время действия донатной системы, вы можете заплатить 1 рубль, чтобы сейчас перейти на месячную подписку. Платёж нужен, чтобы платёжная система запомнила вашу банковскую карточку (мы не храним эту информацию). Срок действия подписки будет равен оставшемуся сроку текущего доступа.<br />
                        Списание по тарифу произойдёт по окончании текущего доступа.<br />
                        Подписку можно будет отменить в любой момент, и доступ к приложению останется до конца оплаченного периода.
                    </p>
                    <div
                        className={`${classes['cabinet-settings__forms']} ${classes['cabinet-settings__forms--no-offset']}`}
                    >
                        <CabinetSubscriptionPlans isPromoActive isDonator />
                    </div>
                </div>
            </CustomContainer>
        );
    }

    if (!subscriptionData?.isActive) {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>
                    <p className={classes['cabinet-settings__text']}>
                        У вас сейчас нет активных подписок.
                        <br />
                        Стоимость подписки составляет 500 рублей в месяц. Подписка устанавливается
                        на календарный месяц. При оформлении подписки вы соглашаетесь на
                        автоматическое списание средств. Подписку можно будет отменить в любой
                        момент, и доступ к приложению останется до конца оплаченного периода.
                    </p>
                    <div
                        className={`${classes['cabinet-settings__forms']} ${classes['cabinet-settings__forms--no-offset']}`}
                    >
                        <CabinetSubscriptionPlans isPromoActive isDonator={false} />
                    </div>
                </div>
            </CustomContainer>
        );
    }

    if (subscriptionData?.hasFirstSubscriptionId && subscriptionData?.isActive) {
        return (
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>
                    <p className={classes['cabinet-settings__text']}>
                        <b>У вас уже есть подписка. Спасибо!</b>
                    </p>
                    <p className={classes['cabinet-settings__text']}>
                        Тарифный план: <b>{mapSubscriptionType(subscriptionData?.type)}</b>
                    </p>
                    <p className={classes['cabinet-settings__text']}>
                        {isAutoRenew ? 'Следующий платёж:' : 'Подписка активна до:'}{' '}
                        <b>{subscriptionEndDate}</b>
                    </p>
                </div>
            </CustomContainer>
        );
    }

    return (
        <section className={classes.cabinet} aria-labelledby="cabinet_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                    mt={18}
                >
                    Подписка для лояльных пользователей
                </Typography>
                <div>Статус подписки неизвестен. Пожалуйста, обратитесь к разработчикам.</div>
            </CustomContainer>
        </section>
    );
}

export default CabinetSettingsSubscriptionStatusPromo;
