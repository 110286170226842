import { buildApiUrl } from './api';

export type Subscription = {
    isActive: boolean;
    hasFirstSubscriptionId?: boolean;
    autoRenew?: boolean;
    type?: string;
    isTrial?: boolean;
    activeUntil?: string;
    paymentUrl?: string;
};

export type SubscriptionType = 'TRIAL' | 'MTH' | 'YEAR' | 'TEST' | 'GIFT' | 'LFT' | 'MTH_PROMO_24';
type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulGetBody = {
    subscription: Subscription;
};

type SuccessfulCreateBody = {
    subscription: Subscription;
};

type BadRequestBody = FaultResponse;

type SubscriptionServiceGetResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulGetBody | PromiseLike<SuccessfulGetBody>;
};

type SubscriptionServiceCreateResponse =
    | (Omit<Response, 'json'> & {
        status: 200;
        json: () => SuccessfulCreateBody | PromiseLike<SuccessfulCreateBody>;
    })
    | (Omit<Response, 'json'> & {
        status: 400;
        json: () => BadRequestBody | PromiseLike<BadRequestBody>;
    });

export type SubscriptionServiceCreateRequest = {
    type?: SubscriptionType;
};

type SuccessfulCancelBody = {
    status: 'ok';
};

type SubscriptionServiceCancelResponse =
    | (Omit<Response, 'json'> & {
        status: 200;
        json: () => SuccessfulCancelBody | PromiseLike<SuccessfulCancelBody>;
    })
    | (Omit<Response, 'json'> & {
        status: 400;
        json: () => BadRequestBody | PromiseLike<BadRequestBody>;
    });

class SubscriptionService {
    static async get() {
        const response = (await fetch(buildApiUrl('/subscription'), {
            method: 'GET'
        })) as SubscriptionServiceGetResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async create(data: SubscriptionServiceCreateRequest) {
        const response = (await fetch(buildApiUrl('/subscription/create'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as SubscriptionServiceCreateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async cancel() {
        const response = (await fetch(buildApiUrl('/subscription/cancel'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })) as SubscriptionServiceCancelResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async restart() {
        const response = (await fetch(buildApiUrl('/subscription/restart'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })) as SubscriptionServiceCancelResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async update(data: SubscriptionServiceCreateRequest) {
        const response = (await fetch(buildApiUrl('/subscription/update'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as SubscriptionServiceCreateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default SubscriptionService;
